import React from 'react'
import SocialFooter from './SocialFooter'
import TechnoIcons from './TechnoIcons'

const Footer = class extends React.Component {
  render() {
    return (
      <footer>
        <div className="w-full mx-auto mt-4 shadow-lg md:w-2/3 lg:w-1/2">
          <div className="w-full subpixel-antialiased bg-black rounded-t h-52 opacity-90">
            <div
              className="flex items-center h-6 text-center text-black bg-gray-200 border border-b-0 border-gray-400 rounded-t"
              id="headerTerminal"
            >
              <div
                className="flex items-center w-3 h-3 ml-2 text-center bg-red-500 border-red-900 rounded-full shadow-inner"
                id="closebtn"
              ></div>
              <div
                className="w-3 h-3 ml-2 bg-yellow-500 border-yellow-900 rounded-full shadow-inner"
                id="minbtn"
              ></div>
              <div
                className="w-3 h-3 ml-2 bg-green-500 border-green-900 rounded-full shadow-inner"
                id="maxbtn"
              ></div>
              <div className="mx-auto" id="terminaltitle">
                <p className="text-sm text-center">Terminal</p>
              </div>
              <div className="mr-2">
                <SocialFooter />
              </div>
            </div>
            <div
              className="flex flex-col h-auto pl-1 space-y-2 font-mono text-sm text-purple-400 bg-black"
              id="console"
            >
              <pre className="mt-2 text-xs leading-none tracking-tighter text-white">
                  <span>#   ██╗ ██████╗ ██╗</span>  
                  <br />
                  <span>#  ██╔╝██╔═══██╗╚██╗</span>
                  <br />
                  <span># ██╔╝ ██║   ██║ ╚██╗</span>
                  <br />
                  <span># ╚██╗ ██║▄▄ ██║ ██╔╝</span>
                  <br />
                  <span>#  ╚██╗╚██████╔╝██╔╝</span>
                  <br />
                  <span>#   ╚═╝ ╚══▀▀═╝ ╚═╝</span>
                  <br /> 
              </pre>
              <p className="pb-1">
                Last login: &copy; {new Date().getFullYear()}{' '}
              </p>
              <p className="pb-1">
                Crafted with{' '}
                <span role="img" aria-label="love">
                  ❤️
                </span>{' '}
                by <a href="https://qwanticode.fr">Qwanticode</a>
              </p>
              <div className="flex flex-wrap">
                <p className="pb-1 mr-2">Built with:</p>
                <TechnoIcons />
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
