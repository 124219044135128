import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import {
  FaFacebook,
  FaTwitter,
  FaLinkedinIn,
  FaGithubAlt,
} from 'react-icons/fa'

const SocialFooter = () => {
  const {
    site: {
      meta: { links },
    },
  } = useStaticQuery(graphql`
    query SocialFooterQuery {
      site {
        meta: siteMetadata {
          links {
            facebook
            instagram
            pinterest
            twitter
            linkedin
            github
          }
        }
      }
    }
  `)

  return (
    <div>
      <ul className="flex justify-center space-x-4 md:order-2">
        <FooterLink href={links.facebook} icon={FaFacebook} label="Facebook" />
        <FooterLink href={links.twitter} icon={FaTwitter} label="Twitter" />
        <FooterLink
          href={links.linkedin}
          icon={FaLinkedinIn}
          label="Linkedin"
        />
        <FooterLink href={links.github} icon={FaGithubAlt} label="GitHub" />
      </ul>
    </div>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <li className="flex">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="transition duration-300 ease-in-out transform hover:text-primary" //hover:-translate-y-2 --> Social icon translation
      >
        <span className="sr-only">{label}</span>
        <Icon />
      </a>
    </li>
  )
}

export default SocialFooter
