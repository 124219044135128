import { graphql, useStaticQuery, Link } from 'gatsby'
import React from 'react'
import ThemeChanger from './ThemeChanger'
import useScrollListener from './hooks/useScrollListener'
import SideBar from './SideBar'

function Navigation() {
  let isScrolled = useScrollListener()
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          mainMenu {
            title
            path
          }
        }
      }
    }
  `)

  return (
    <header
      className={`${
        isScrolled
          ? `shadow-md lg:shadow-lg lg:dark:shadow-white`
          : `shadow-none`
      } 
    sticky top-0 z-30 h-16 bg-purple-50 dark:bg-primary transition duration-300 ease-linear`}
    >
      <div className="flex flex-wrap items-center justify-between max-w-6xl p-4 mx-auto ">
        <Link to="/">
          <h1 className="flex items-center no-underline">
            <svg
              width="86"
              height="40"
              version="1.1"
              viewBox="0 0 22.754 10.583"
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 mr-2 fill-current w-14"
            >
              <g transform="translate(-.5 -.50023)">
                <path
                  d="m5.7917 1.8229v1.5875l-3.9687 2.3813 3.9687 2.3812v1.5875l-5.2917-3.175v-1.5875z"
                  strokeWidth=".40272"
                />
                <path
                  transform="scale(.26458)"
                  d="m44.891 1.8906c-11.046 1.3e-6 -20 8.9543-20 20-8e-6 11.046 8.9772 20 20 20s7.9991-8.28e-4 18.999-8.28e-4c-3-8-3.5938-7-1.6554-10.034 1.5192-2.3782 2.6563-6.3361 2.6562-9.9648-8e-6 -11.046-8.9543-20-20-20zm-0.01367 7.5h0.01367c6.9036 6e-7 12.5 5.5964 12.5 12.5-1e-6 6.9036-5.5964 12.5-12.5 12.5-6.9036-1e-6 -12.5-5.5964-12.5-12.5-4e-6 -6.8982 5.5881-12.492 12.486-12.5z"
                  fill="#f487a6"
                />
                <path
                  d="m17.962 1.8229v1.5875l3.9688 2.3813-3.9688 2.3812v1.5875l5.2917-3.175v-1.5875z"
                  strokeWidth=".40272"
                />
              </g>
            </svg>
            <span className="text-xl font-bold tracking-tight">
              {site.siteMetadata.title}
            </span>
          </h1>
        </Link>
        <SideBar />
        <nav className="inline-flex w-auto space-x-4 text-gray-900 bg-transparent sr-only md:not-sr-only dark:text-white">
          {[
            {
              route: `/about`,
              title: `A propos`,
            },
            {
              route: `/contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className="flex justify-end mt-4 no-underline transition duration-300 ease-linear border-purple-50 lg:border-b-2 dark:border-primary hover:border-purple-500 dark:hover:border-secondary md:items-end md:mt-0 "
              activeClassName="font-bold"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
          <div className="flex items-center pb-1 pl-4 border-l border-gray-400 justify-items-end focus:outline-none">
            <ThemeChanger />
          </div>
          <SideBar />
        </nav>
      </div>
    </header>
  )
}

export default Navigation
