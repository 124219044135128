import React from 'react'
// import useSiteMetadata from './SiteMetadata'
import Navigation from './Navigation'
import Footer from '../components/Footer'

const TemplateWrapper = ({ children }) => {
  return (
    //move to SEO
    <div className="flex flex-col min-h-screen">
      <Navigation />
      {/* flex-1 to fix min-h */}
      <main className="flex-1 w-full max-w-6xl px-4 py-4 mx-auto md:px-8 lg:py-16">
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
