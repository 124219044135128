import React from 'react'
import { FiMoon } from 'react-icons/fi'
import { FiSun } from 'react-icons/fi'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

export default () => (
  <ThemeToggler>
    {({ toggleTheme, theme }) => {
      const isDarkMode = theme === 'dark'
      if (theme == null) {
        return null
      }

      return (
        <button
          aria-label="theme-switch"
          className="focus:outline-none"
          onClick={() => toggleTheme(isDarkMode ? 'light' : 'dark')}
        >
          {isDarkMode ? (
            <FiMoon className="w-5 h-5 fill-current" />
          ) : (
            <FiSun className="w-5 h-5 fill-current" />
          )}
        </button>
      )
    }}
  </ThemeToggler>
)
