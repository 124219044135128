import React, { useState } from 'react'
import { Transition } from '@headlessui/react'
import { HiX, HiMenu } from 'react-icons/hi'
import { FaHome, FaIdCardAlt, FaPenAlt, FaEnvelope } from 'react-icons/fa'
import { Link } from 'gatsby'
import ThemeChanger from './ThemeChanger'
/*

** Sidebar with headlessui (Tailwind react transitions) 

* https://tailwindui.com/components/application-ui/overlays/slide-overs

* https://github.com/tailwindlabs/headlessui/blob/develop/packages/%40headlessui-react/README.md#showinghiding-the-menu

*

*

*/
const SideBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-3 py-2 border rounded md:hidden"
      >
        <HiMenu className="fill-current" />
      </button>
      <Transition show={isOpen}>
        <div className="fixed inset-0 -mb-16 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              enter="transition-opacity ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                onClick={() => setIsOpen(!isOpen)}
                className="absolute inset-0 transition-opacity bg-black bg-opacity-50"
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <Transition.Child
              enter="transition ease-linear duration-500 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-linear duration-500 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <section
                className="absolute inset-y-0 right-0 z-30 flex max-w-full shadow-lg"
                aria-labelledby="slide-over-heading"
              >
                <div className="relative w-56 sm:w-96 max-w-screen rounded-l-md">
                  <nav className="flex flex-col h-screen text-gray-900 bg-white outline-none dark:bg-primary dark:text-white">
                    <div className="flex items-center w-full p-4 border-b border-purple-500">
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="flex items-center px-3 py-2 border rounded md:hidden"
                      >
                        <span className="sr-only">Close panel</span>
                        <HiX className="fill-current" />
                      </button>
                    </div>
                    {[
                      {
                        route: `/`,
                        title: `Accueil`,
                        icon: <FaHome className="w-6 h-6 mr-4 fill-current" />,
                      },
                      {
                        route: `/about`,
                        title: `A propos`,
                        icon: (
                          <FaIdCardAlt className="w-6 h-6 mr-4 fill-current" />
                        ),
                      },
                      {
                        route: `/contact`,
                        title: `Contact`,
                        icon: (
                          <FaEnvelope className="w-6 h-6 mr-4 fill-current" />
                        ),
                      },
                    ].map((link) => (
                      <Link
                        className="flex p-4 hover:bg-purple-500 hover:text-white "
                        activeClassName="font-bold text-secondary"
                        key={link.icon} // eslint-disable-next-line
                        key={link.title}
                        to={link.route}
                      >
                        {link.icon}
                        {link.title}
                      </Link>
                    ))}
                    <div className="flex flex-row-reverse p-4 border-t border-purple-500">
                      <ThemeChanger />
                    </div>
                  </nav>
                </div>
              </section>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default SideBar
