import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import {
  SiReact,
  SiJavascript,
  SiTailwindcss,
  SiGatsby,
  SiNetlify,
  SiMarkdown,
} from 'react-icons/si'

const TechnoIcons = () => {
  const {
    site: {
      meta: { links },
    },
  } = useStaticQuery(graphql`
    query TechnoIconsQuery {
      site {
        meta: siteMetadata {
          links {
            facebook
            instagram
            pinterest
            twitter
            linkedin
            github
            gatsby
            netlify
            react
            js
            tailwind
            markdown
          }
        }
      }
    }
  `)

  return (
    <div>
      <ul className="inline-flex space-x-1 md:order-2">
        <TechnoLink href={links.react} icon={SiReact} label="Facebook" />
        <TechnoLink href={links.js} icon={SiJavascript} label="Twitter" />
        <TechnoLink
          href={links.tailwind}
          icon={SiTailwindcss}
          label="Linkedin"
        />
        <TechnoLink href={links.gatsby} icon={SiGatsby} label="GitHub" />
        <TechnoLink href={links.netlify} icon={SiNetlify} label="GitHub" />
        <TechnoLink href={links.markdown} icon={SiMarkdown} label="GitHub" />
      </ul>
    </div>
  )
}

const TechnoLink = ({ href, label, icon: Icon }) => {
  return (
    <li className="flex">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="text-white transition duration-300 ease-in-out transform" //hover:-translate-y-2 --> Social icon translation
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-4 h-4" />
      </a>
    </li>
  )
}

export default TechnoIcons
